<template>
  <table class="table datatable-basic" v-bind:class="hoverable ? 'table-hover': ''">
    <TableElement>
      <template v-slot:default>
        <slot name="header"></slot>
      </template>
    </TableElement>
    <tbody>
      <slot></slot>
    </tbody>
    <tfoot>
      <slot name="footer" />
    </tfoot>
  </table>
  <br class="clearfix"/>
  <div v-if="hasPagination && 0 !== pagination.getTotal()" class="row">
    <Pagination @page="onPage" :pagination="pagination" />
  </div>
</template>

<script>
import TableElement from "@/components/elements/TableElement";
import Pagination from "@/components/elements/Pagination";
export default {
  name: "Table",
  components: {Pagination, TableElement},
  emits: ['page'],
  props: {
    pagination: {
      type: Object,
      default: null
    },
    hoverable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasPagination: function () {
      return null !== this.pagination;
    }
  },
  methods: {
    onPage(pageNr) {
      this.$emit('page', pageNr);
    }
  }
}
</script>

<style scoped>
</style>