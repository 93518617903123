<template>
  <span>{{ price }}</span>
</template>

<script>
import Configuration from "@/entities/Configuration";
import {NumbersMixin} from "@/mixins/GeneralMixin";

export default {
  name: "Price",
  mixins: [NumbersMixin],
  props: {
    currency: String,
    amount: [String,Number],
  },
  data() {
    return {
      config: Configuration.getSingleton()
    }
  },
  computed: {
    price: function () {
      return this.formatPrice(this.amount, this.currency);
    }
  }
}
</script>

<style scoped>

</style>