<template>
  <tr v-if="loading">
    <TableElementItem :colspan="size" :centered="true">
      <template v-slot:default>
        <Loading :hasText="false" />
      </template>
    </TableElementItem>
  </tr>
  <tr v-else-if="!emptyMessage">
      <slot />
  </tr>
  <tr v-else>
    <TableElementItem :colspan="size" :centered="true">
      <template v-slot:default>
        <h3>{{ emptyMessage }}</h3>
      </template>
    </TableElementItem>
  </tr>
</template>

<script>
import TableElementItem from "@/components/elements/TableElementItem";
import Loading from "@/components/elements/Loading";
export default {
  name: "TableElement",
  components: {Loading, TableElementItem},
  props: {
    size: Number,
    emptyMessage: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data() {
      return {
        hover: false,
      }
    }
  }
}
</script>

<style scoped>
.table.table-hover tbody tr {
  cursor: pointer;
}
</style>
