<template>
  <Modal :with-footer="true" @closemodal="$emit('closemodal')" :dismissible="true">
    <template v-slot:default>
      <h3 v-if="withPrefix" class="text-center">{{ $t('general.confirmation.action', {action:action}) }}</h3>
      <h3 v-else class="text-center">{{ action }}</h3>
    </template>
    <template v-slot:footer>
      <Button @click="$emit('closemodal')" type="link" :name="$t('general.buttons.cancel')"/>
      <Button @click="$emit('confirmmodal')" type="confirm" :name="$t('general.buttons.confirm')"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
export default {
  name: "ConfirmationModal",
  emits: ['confirmmodal', 'closemodal'],
  components: {Button, Modal},
  props: {
    action: String,
    withPrefix: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style scoped>

</style>