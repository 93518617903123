<template>
  <span v-bind:class="elementStyle">{{ qty }} <span v-if="unit">{{ unit }}</span></span>
</template>

<script>
import Configuration from "@/entities/Configuration";
import {NumbersMixin} from "@/mixins/GeneralMixin";

export default {
  name: "Qty",
  mixins: [NumbersMixin],
  props: {
    amount: Number,
    unit: {
      type: String,
      default: '',
    },
    elementStyle: {
      type: String,
      default: "d-block text-center col-12"
    }
  },
  data() {
    return {
      config: Configuration.getSingleton()
    }
  },
  computed: {
    qty: function () {
      return this.formatQty(this.amount);
    }
  }
}
</script>

<style scoped>

</style>