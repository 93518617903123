<template>
  <th v-bind:colspan="colspan" v-if="isHeader" v-bind:class="style">
    <slot></slot>
  </th>
  <td v-bind:colspan="colspan" v-else v-bind:class="style">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TableElementItem",
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    colspan: {
      type: Number,
      default: 1,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style: function () {
      let style = '';
      if (this.centered) {
        style+='text-center ';
      }
      if (this.borderless) {
        style+='border-top-0 ';
      }

      return style;
    }
  }
}
</script>

<style scoped>

</style>