<template>
  <span v-bind:class="farmer.isDeleted() && color" class="d-inline-flex"><i v-if="farmer.isDeleted()" class="icon-warning pr-2"></i>{{ farmer.getName() }}</span>
    <div v-if="farmer.sellsImportProducts()">
        <span class="badge badge-info" >{{$t('farmers.import')}}</span>
    </div>
</template>

<script>
export default {
  name: "FarmerName",
  props: {
    farmer: Object,
    color: {
      type: String,
      default: 'text-warning'
    }
  }
}
</script>

<style scoped>

</style>
