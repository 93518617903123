<template>
  <div class="card card-body border-0">
    <ul class="pagination align-self-md-end align-self-center">
      <li class="page-item">
        <Button :key="nrVisiblePages" v-bind:disabled="!hasPrevious"
                @click="$emit('page', pagination.prev(nrVisiblePages))" type="link-prev"
                name="general.pagination.prev"/>
      </li>

      <li v-for="page in visiblePages" v-bind:key="page" class="page-item ml-2"
          v-bind:class="pagination.isCurrentPage(page) ? 'active':''">
        <Button @click="$emit('page', page)" type="link" :name="''+page"
                v-bind:disabled="pagination.isCurrentPage(page)"/>
      </li>

      <li class="page-item ml-2">
        <Button :key="nrVisiblePages" type="link-next" name="general.pagination.next" v-bind:disabled="!hasNext"
                @click="$emit('page', pagination.next(nrVisiblePages))"/>
      </li>
    </ul>
  </div>
</template>

<script>
import Button from "@/components/elements/Button";
import {ScreenSizeMixin} from "@/mixins/GeneralMixin";

export default {
  name: "Pagination",
  components: {Button},
  mixins: [ScreenSizeMixin],
  emits: ['page'],
  props: {
    pagination: {
      type: Object
    },
  },
  computed: {
    nrVisiblePages: function () {
      if (this.isScreenLgWidth) {
        return 6;
      }

      return 3;
    },
    hasPrevious: function () {
      return (this.visiblePages[0]) > 1;
    },
    hasNext: function () {
      return (this.visiblePages[this.visiblePages.length-1]) < this.pagination.getTotalPages();
    },
    visiblePages: function () {
      let that = this;
      let pages = [this.pagination.getCurrentPage()];
      let visiblePages = this.nrVisiblePages - 1;
      let left = 0;
      let right = 0;

      while (visiblePages > 0) {
        pages.push(this.pagination.getCurrentPage() - visiblePages);
        pages.push(this.pagination.getCurrentPage() + visiblePages);
        visiblePages--;
      }

      pages = pages.filter(function (page) {
        return page > 0 && page <= that.pagination.getTotalPages();
      });

      for (let page of pages) {
        if (page < this.pagination.getCurrentPage()) {
          left ++;
        } else if (page > this.pagination.getCurrentPage()) {
          right ++;
        }
      }

      pages.sort(function (a, b) {  return a - b;  });

      while (pages.length > this.nrVisiblePages) {
        if (left >= right) {
          pages.shift();
          left--;
        } else {
          pages.pop();
          right--
        }
      }

      pages.sort(function (a, b) {  return a - b;  });
      return pages;
    }
  },
}
</script>

<style scoped>

</style>