<template>
  <span v-bind:class="color" class="font-size-lg badge">{{ $t(status) }}</span>
</template>

<script>
export default {
  name: "Status",
  props: {
    status: String,
  },
  computed: {
    color: function () {
      switch (this.status) {
        case 'users.inactive':
        case 'modified':
          return 'badge-warning';
        default:
          return 'badge-success';
      }
    }
  }
}
</script>

<style scoped>

</style>